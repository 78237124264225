.csv-input {
  width: 190%;
  height: 100%;
  opacity: 0;
  background-color: white;
  font-family: Poppins;
  cursor: pointer;
  padding-right: 2rem;
  margin-left: -90%;
}

.csv-reader-input {
  width: 100%;
  z-index: 2000;
  cursor: pointer;
}
