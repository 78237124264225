/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 21, 2018 */

/******************* Branding *********************/

@font-face {
    font-family: 'Branding';
    src: url('branding-black-webfont.woff2') format('woff2'),
         url('branding-black-webfont.woff') format('woff');
    font-weight: 900;
}

@font-face {
    font-family: 'Branding';
    src: url('branding-blackitalic-webfont.woff2') format('woff2'),
         url('branding-blackitalic-webfont.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Branding';
    src: url('branding-bold-webfont.woff2') format('woff2'),
         url('branding-bold-webfont.woff') format('woff');
    font-weight: bold;
}

@font-face {
    font-family: 'Branding';
    src: url('branding-bolditalic-webfont.woff2') format('woff2'),
         url('branding-bolditalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Branding';
    src: url('branding-medium-webfont.woff2') format('woff2'),
         url('branding-medium-webfont.woff') format('woff');
}

@font-face {
    font-family: 'Branding';
    src: url('branding-mediumitalic-webfont.woff2') format('woff2'),
         url('branding-mediumitalic-webfont.woff') format('woff');
    font-style: italic;
}

@font-face {
    font-family: 'Branding';
    src: url('branding-semibold-webfont.woff2') format('woff2'),
         url('branding-semibold-webfont.woff') format('woff');
    font-weight: 600;
}

@font-face {
    font-family: 'Branding';
    src: url('branding-semibolditalic-webfont.woff2') format('woff2'),
         url('branding-semibolditalic-webfont.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

/******************* Saira *********************/

@font-face {
    font-family: 'Saira';
    src: url('SairaExtraCondensed-Bold.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'Saira';
    src: url('SairaExtraCondensed-ExtraBold.ttf') format('truetype');
    font-weight: 800;
}

@font-face {
    font-family: 'Saira';
    src: url('SairaExtraCondensed-Light.ttf') format('truetype');
    font-weight: lighter;
    
}

@font-face {
    font-family: 'Saira';
    src: url('SairaExtraCondensed-ExtraLight.ttf') format('truetype');
    font-weight: 100;
}

@font-face {
    font-family: 'Saira';
    src: url('SairaExtraCondensed-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Saira';
    src: url('SairaExtraCondensed-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Saira';
    src: url('SairaExtraCondensed-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

/******************** Catamaran *********************/

@font-face {
    font-family: 'Catamaran';
    src: url('Catamaran-Bold.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'Catamaran';
    src: url('Catamaran-ExtraBold.ttf') format('truetype');
    font-weight: 800;
}

@font-face {
    font-family: 'Catamaran';
    src: url('Catamaran-Light.ttf') format('truetype');
    font-weight: lighter;
}

@font-face {
    font-family: 'Catamaran';
    src: url('Catamaran-ExtraLight.ttf') format('truetype');
    font-weight: 100;
}

@font-face {
    font-family: 'Catamaran';
    src: url('Catamaran-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Catamaran';
    src: url('Catamaran-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Catamaran';
    src: url('Catamaran-SemiBold.ttf') format('truetype');
    font-weight: 600;
}


/******************** Poppins *********************/

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face{
    font-family:'Poppins';
    src: url('Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face{
    font-family:'Poppins';
    src: url('Poppins-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face{
    font-family:'Poppins';
    src: url('Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face{
    font-family:'Poppins';
    src: url('Poppins-Black.ttf') format('truetype');
    font-weight: 900;
}

@font-face{
    font-family:'Poppins';
    src: url('Poppins-Regular.ttf') format('truetype');
    font-weight: 500;
}

@font-face{
    font-family:'Poppins';
    src: url('Poppins-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face{
    font-family:'Poppins';
    src: url('Poppins-LightItalic.ttf') format('truetype');
    font-weight: 100;
}

/************************* Fontello *****************************/

@font-face{
    font-family:'fontello';
    src: url('fontello.ttf') format('truetype');
    font-weight: 500;
}