
/*--------------------------------------------------------------------------------------------------------------*/
/*                                               GRID ARCHIVE ADMIN                                                */
.ag-theme-alpine-Admin-Archive .ag-header-viewport{
  background: white;
  text-decoration:none;
}

.ag-theme-alpine-Admin-Archive .ag-theme-alpine .ag-header-row{
  height:60px;
}

.ag-theme-alpine-Admin-Archive .ag-ltr .ag-body-viewport{
  margin-top:-1rem;
}

.ag-theme-alpine-Admin-Archive .ag-header-cell-text{
  font-size: 0.90rem;
  padding-left: 1rem;
  font-family: sans-serif;
  font-weight: 600;
  white-space: pre-wrap !important;
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
  
.ag-theme-alpine-Admin-Archive .ag-header-cell{
  padding-left:0px;
  border: 0.1rem solid white;
  padding-right:0px;
  text-decoration:none;
  box-sizing: border-box;
}

.ag-theme-alpine-Admin-Archive .ag-root-wrapper{
  border:none;
  text-decoration:none;
}

.ag-theme-alpine-Admin-Archive .ag-header{
  border:none;
  text-decoration:none;
}

.ag-theme-alpine-Admin-Archive .ag-cell{
  line-height: 36px;
  text-decoration:none;
}

.ag-theme-alpine-Admin-Archive .ag-ltr .ag-cell{
  border-radius:0.5rem;
  line-height: 3.6;
  text-decoration:none;
  font-family: sans-serif;
  box-sizing: border-box;
}

.ag-theme-alpine-Admin-Archive .ag-ltr .ag-cell:nth-child(1),
.ag-theme-alpine-Admin-Archive .ag-ltr .ag-cell:nth-child(3){
  text-decoration:none;
  border-right: 0.2rem solid white;
  border-radius:0.5rem;
  padding-left: 10px;
}

.ag-theme-alpine-Admin-Archive .ag-ltr .ag-cell:nth-child(2){
  text-decoration:none;
  border-right: 0.2rem solid white;
  border-radius:0.5rem;
  padding-left: 0.7rem;
}
  
.ag-theme-alpine-Admin-Archive .ag-ltr .ag-cell:nth-child(4),
.ag-theme-alpine-Admin-Archive .ag-ltr .ag-cell:nth-child(7),
.ag-theme-alpine-Admin-Archive .ag-ltr .ag-cell:nth-child(8){
  text-align: center;
  border-right: 0.2rem solid white;
  border-radius:0.5rem;
}
  
.ag-theme-alpine-Admin-Archive .ag-ltr .ag-cell:nth-child(6){
    border-right: 0.2rem solid white;
    border-radius:0.5rem;
    text-decoration:none;
    text-align: center;
}
    
.ag-theme-alpine-Admin-Archive .ag-ltr .ag-cell:nth-child(5){
  border-right: 0.2rem solid white;
  border-radius:0.5rem;
  text-decoration:none;
  text-align: center;
}
  
.ag-theme-alpine-Admin-Archive .rowGreyBackgroundwithImg{
  background-color: #f6f6f6;
  text-align:'center';
  text-decoration:none;
  background-image: url('../../images/boy.svg');
  background-position: left 7% top 52%;
  background-repeat: no-repeat;
  background-size: 1.2rem;
  text-align:'left';
  text-decoration:none;
}
  
.ag-theme-alpine-Admin-Archive .rowGreyBackgroundwithAgencyImg{
  background-image: url('../../images/car.svg');
  background-position: left 7% top 52%;
  background-repeat: no-repeat;
  background-size: 1.2rem;
  background-color: #f6f6f6;
  text-align:'left';
  text-decoration:none;
}
.ag-theme-alpine-Admin-Archive .rowGreyBackground{
  background-color: #f6f6f6;
  text-align:'center';
  text-decoration:none;
}

.ag-theme-alpine-Admin-Archive .rowBlueBackground{
  background-color: #e6f3ff;
  text-align:'center';
  text-decoration:none;
}

.ag-theme-alpine-Admin-Archive .rowGreenBackground{
  background-color:#DFF7E6;
  text-align:'center';
  text-decoration:none;
}

.ag-theme-alpine-Admin-Archive .ag-input-field-input{
  height:2.7rem;
  width:100%;
}

.ag-theme-alpine-Admin-Archive .ag-ltr input[class^="ag-"][type="text"]{
  color:black;
  text-decoration: none;
  height: 30px;
  font-size: 14px;
  z-index: 1000;
  border: 1px solid hsl(0, 0%, 80%);
  padding: 0px 0px 0px 2.5rem;
  border-radius: 4px;
  background-size: 1rem;
  background-repeat: no-repeat;
  background-position: left 1rem bottom 50%;
  background-image: url('../../imagesMB/search.svg');
  margin: 0px 1px 0px 1px;
  cursor: pointer;
}

.ag-theme-alpine-Admin-Archive .ag-header-row:not(:first-child) .ag-header-cell{
  background:#eff0f6;
  border-radius: 0px;  
  height:43px;
  border: none;
}

.ag-theme-alpine-Admin-Archive .ag-header-row:not(:first-child) .ag-header-cell:nth-child(1){
  background:#eff0f6;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;  
}

.ag-theme-alpine-Admin-Archive .ag-header-row:not(:first-child) .ag-header-cell:nth-child(9){
  background:#eff0f6;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;  
}

.ag-theme-alpine-Admin-Archive .ag-header-row:not(:first-child) .ag-header-cell:nth-child(3),
.ag-theme-alpine-Admin-Archive .ag-header-row:not(:first-child) .ag-header-cell:nth-child(4),
.ag-theme-alpine-Admin-Archive .ag-header-row:not(:first-child) .ag-header-cell:nth-child(5),
.ag-theme-alpine-Admin-Archive .ag-header-row:not(:first-child) .ag-header-cell:nth-child(6),
.ag-theme-alpine-Admin-Archive .ag-header-row:not(:first-child) .ag-header-cell:nth-child(7),
.ag-theme-alpine-Admin-Archive .ag-header-row:not(:first-child) .ag-header-cell:nth-child(8),
.ag-theme-alpine-Admin-Archive .ag-header-row:not(:first-child) .ag-header-cell:nth-child(10){
  background:#eff0f6;
  border-radius: 0px;
}

.ag-theme-alpine-Admin-Archive .ag-header-row .ag-header-cell{
  background:#999999;
  color:white;
  border:1px solid white;
  border-radius: 5px;
}

.ag-theme-alpine-Admin-Archive .ag-header-row .ag-header-cell:nth-child(4){
  background:#53AD49;
  color:white;
}

.ag-theme-alpine-Admin-Archive .ag-header-row .ag-header-cell:nth-child(5),
.ag-theme-alpine-Admin-Archive .ag-header-row .ag-header-cell:nth-child(7),
.ag-theme-alpine-Admin-Archive .ag-header-row .ag-header-cell:nth-child(8){
  background:#999999;
  color:white;
}

.ag-theme-alpine-Admin-Archive .ag-header-row .ag-header-cell:nth-child(9){
  background:#297fcc;
  color:white;
}
  
.ag-theme-alpine-Admin-Archive .ag-row{
  border-width:0px;
  border-bottom-style: none;
  border: 0.1rem solid white;
  border-top: 0.2rem solid white;
}
  
  
.ag-theme-alpine-Admin-Archive a{
  text-decoration: none;
  color:black;
  font-size:1rem;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
}
  
.ag-theme-alpine-Admin-Archive .ag-ltr .ag-body-viewport{
  margin-top:-0.1rem;
}

/*-----------------------------------------------------------------------------------------*/
/*                                     GRID ARCHIVE                                        */


.ag-theme-alpine-Archive .ag-header-viewport{
  background: white;
  text-decoration:none;
}

.ag-theme-alpine-Archive .ag-theme-alpine .ag-header-row{
  height:60px;
}

.ag-theme-alpine-Archive .ag-ltr .ag-body-viewport{
  margin-top:-1rem;
}

.ag-theme-alpine-Archive .ag-header-cell-text{
  font-size: 0.90rem;
  padding-left: 1rem;
  font-family: sans-serif;
  font-weight: 600;
  white-space: pre-wrap !important;
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.ag-theme-alpine-Archive .ag-header-cell{
  padding-left:0px;
  border: 0.1rem solid white;
  padding-right:0px;
  text-decoration: none;
  box-sizing: border-box;
}

.ag-theme-alpine-Archive .ag-root-wrapper{
  border:none;
  text-decoration:none;
}

.ag-theme-alpine-Archive .ag-header{
  border:none;
  text-decoration:none;
}

.ag-theme-alpine-Archive .ag-cell{
  line-height: 36px;
  text-decoration:none;
}

.ag-theme-alpine-Archive .ag-ltr .ag-cell{
  border-radius:0.5rem;
  line-height: 3.6;
  text-decoration:none;
  font-family: sans-serif;
  box-sizing: border-box;
}

.ag-theme-alpine-Archive .ag-ltr .ag-cell:nth-child(1),
.ag-theme-alpine-Archive .ag-ltr .ag-cell:nth-child(2){
  border-right: 0.2rem solid white;
  border-radius:0.5rem;
  padding-left: 10px;
}

.ag-theme-alpine-Archive .ag-ltr .ag-cell:nth-child(3),
.ag-theme-alpine-Archive .ag-ltr .ag-cell:nth-child(4),
.ag-theme-alpine-Archive .ag-ltr .ag-cell:nth-child(5),
.ag-theme-alpine-Archive .ag-ltr .ag-cell:nth-child(6),
.ag-theme-alpine-Archive .ag-ltr .ag-cell:nth-child(7){
  text-align: center;
  border-right: 0.2rem solid white;
  border-radius:0.5rem;
}

.ag-theme-alpine-Archive .rowGreyBackgroundwithImg{
  background-color: #f6f6f6;
  text-align:'center';
  text-decoration:none;
  background-image: url('../../images/boy.svg');
  background-position: left 7% top 52%;
  background-repeat: no-repeat;
  background-size: 1.2rem;
  text-align:'left';
  text-decoration:none;
}

.ag-theme-alpine-Archive .rowGreyBackgroundwithAgencyImg{
  background-image: url('../../images/car.svg');
  background-position: left 1rem top 50%;
  background-repeat: no-repeat;
  background-size: 1.2rem;
  background-color: #f6f6f6;
  text-align:'left';
  text-decoration:none;
}

.ag-theme-alpine-Archive .rowGreyBackground{
  background-color: #f6f6f6;
  text-align:'center';
  text-decoration:none;
}

.ag-theme-alpine-Archive .rowBlueBackground{
  background-color: #e6f3ff;
  text-align:'center';
  text-decoration:none;
}

.ag-theme-alpine-Archive .rowGreenBackground{
  background-color:#DFF7E6;
  text-align:'center';
  text-decoration:none;
}

.ag-theme-alpine-Archive .ag-input-field-input{
  height:2.7rem;
  width:100%;
}

.ag-theme-alpine-Archive .ag-ltr input[class^="ag-"][type="text"]{
  color:black;
  text-decoration: none;
  height: 30px;
  font-size: 14px;
  z-index: 1000;
  border: 1px solid hsl(0, 0%, 80%);
  padding: 0px 0px 0px 2.5rem;
  border-radius: 4px;
  background-size: 1rem;
  background-repeat: no-repeat;
  background-position: left 1rem bottom 50%;
  background-image: url('../../imagesMB/search.svg');
  margin: 0px 1px 0px 1px;
  cursor: pointer;
}
.ag-theme-alpine-Archive .ag-header-row:not(:first-child) .ag-header-cell{
  background: none;
  border:none;
  height:43px;
  background:#eff0f6;
  border-radius: 0px;
}

.ag-theme-alpine-Archive .ag-header-row:not(:first-child) .ag-header-cell:nth-child(1){
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.ag-theme-alpine-Archive .ag-header-row:not(:first-child) .ag-header-cell:nth-child(8){
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background:#eff0f6;
}

.ag-theme-alpine-Archive .ag-header-row:not(:first-child) .ag-header-cell:nth-child(3),
.ag-theme-alpine-Archive .ag-header-row:not(:first-child) .ag-header-cell:nth-child(4),
.ag-theme-alpine-Archive .ag-header-row:not(:first-child) .ag-header-cell:nth-child(5),
.ag-theme-alpine-Archive .ag-header-row:not(:first-child) .ag-header-cell:nth-child(6),
.ag-theme-alpine-Archive .ag-header-row:not(:first-child) .ag-header-cell:nth-child(7){
  background:#eff0f6;
  border-radius: 0px;
}

.ag-theme-alpine-Archive .ag-header-row .ag-header-cell{
  background:#999999;
  color:white;
  border:1px solid white;
  border-radius: 5px;
}

.ag-theme-alpine-Archive .ag-header-row .ag-header-cell:nth-child(3){
  background:#53AD49;
  color:white;
}

.ag-theme-alpine-Archive .ag-header-row .ag-header-cell:nth-child(5),
.ag-theme-alpine-Archive .ag-header-row .ag-header-cell:nth-child(7){
  background:#999999;
  color:white;
}

.ag-theme-alpine-Archive .ag-header-row .ag-header-cell:nth-child(8){
  background:#297fcc;
  color:white;
}

.ag-theme-alpine-Archive .ag-row{
  border-width:0px;
  border-bottom-style: none;
  border: 0.1rem solid white;
  border-top: 0.2rem solid white;
}

.ag-theme-alpine-Archive a{
  text-decoration: none;
  color:black;
  font-size:1rem;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
}

.ag-theme-alpine-Archive .ag-ltr .ag-body-viewport{
  margin-top:-0.1rem;
}