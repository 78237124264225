.ag-header-viewport{
  background: white;
  text-decoration:none;
}

.ag-header-cell-text{
  font-size: 0.90rem;
  padding-left: 1rem;
  font-family: sans-serif;
  font-weight: 600;
  align-items: center;
  display: flex;
  justify-content: center;
}

.ag-theme-alpine-agency .ag-header-cell{
  padding-left:0px;
  border: 1px solid white;
  padding-right:0px;
  text-decoration:none;
  box-sizing: border-box;
}

.ag-theme-alpine-agency .ag-root-wrapper{
  border:none;
  text-decoration:none;
}

.ag-theme-alpine-agency .ag-header{
  border:none;
  text-decoration:none;
}

.ag-theme-alpine-agency .ag-cell{
  line-height: 36px;
  text-decoration:none;
}

.ag-theme-alpine-agency .ag-ltr .ag-cell{
  border-radius:0.5rem;
  line-height: 3.6;
  text-decoration:none;
  font-family: sans-serif;
  border: 0.5px solid white;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.ag-theme-alpine-agency .ag-ltr .ag-cell:nth-child(1){
  text-decoration:none;
}

.ag-theme-alpine-agency .ag-ltr .ag-cell:nth-child(2),
.ag-theme-alpine-agency .ag-ltr .ag-cell:nth-child(3){
  text-decoration:none;
  justify-content: center;
}

.ag-theme-alpine-agency .ag-ltr .ag-cell:nth-child(4){
  display: flex;
  justify-content: space-around;
}

.rowGreyBackgroundwithAgencyImg{
  background-image: url('../../images/car.svg');
  background-position: left 1rem top 50%;
  background-repeat: no-repeat;
  background-size: 1.2rem;
  background-color: #f6f6f6;
  text-align:'left';
  text-decoration:none;
}

.rowGreyBackground{
  background-color: #f6f6f6;
  text-align:'center';
  text-decoration:none;
}

.ag-input-field-input{
  height:2.7rem;
  width:100%;
}

.ag-theme-alpine-agency .ag-ltr input[class^="ag-"][type="text"]{
  color:black;
  text-decoration: none;
  height: 30px;
  font-size: 14px;
  z-index: 1000;
  border: 1px solid hsl(0, 0%, 80%);
  padding: 0px 0px 0px 2.5rem;
  border-radius: 4px;
  background-size: 1rem;
  background-repeat: no-repeat;
  background-position: left 1rem bottom 50%;
  background-image: url('../../imagesMB/search.svg');
  margin: 0px 1px 0px 1px;
  cursor: pointer;
}

.ag-theme-alpine-agency .ag-ltr input[class^="ag-"][type="text"]:focus-visible {
  outline-color: #2684FF;
}

.ag-theme-alpine-agency .ag-header-row:not(:first-child) .ag-header-cell{
  background: none;
  border:none;
  height:43px; 
  font-family: Poppins;
}

.ag-theme-alpine-agency .ag-header-row:not(:first-child) .ag-header-cell:nth-child(1) {
  background:#eff0f6;
  border-radius: 5px 0px 0px 5px;
}

.ag-theme-alpine-agency .ag-header-row:not(:first-child) .ag-header-cell:nth-child(2),
.ag-theme-alpine-agency .ag-header-row:not(:first-child) .ag-header-cell:nth-child(3){
  background:#eff0f6;
  border-radius: 0px;
}

.ag-theme-alpine-agency .ag-header-row:not(:first-child) .ag-header-cell:nth-child(4) {
  background:#eff0f6;
  border-radius: 0px 5px 5px 0px;
}

.ag-theme-alpine-agency .ag-header-row .ag-header-cell{
  background:#999999;
  border-radius:5px;
  color:white;
  font-family: Poppins;
}

.ag-theme-alpine-agency .ag-row{
  border-width:0px;
  border-bottom-style: none;
  border: 0.1rem solid white;
  border-top: 0.2rem solid white;
}

.ag-theme-alpine-agency a{
  text-decoration: none;
  color:black;
  font-size:1rem;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
}

.ag-theme-alpine-agency .ag-header-row .ag-header-cell:nth-child(4){
  background:#297fcc;
  color:white;
}
